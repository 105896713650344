export const moduleAuthorization = {
  namespaced: true,
  state: {
    level: "",
  },
  getters: {
    isDevelopmentOrRoot: (state) => (
     process.env.NODE_ENV === 'development' || state.level === 'root'
     // process.env.NODE_ENV === 'development' || state.level === 'root'
     // state.level === 'root'
    ),
  },
  mutations: {
    setAuthorization(state, data) {
        console.log(`[Vuex] setAuthorization 參數 ${JSON.stringify(data)}`)
        state.level = data
    }
  }
}